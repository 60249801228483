<template>
	<div class="hotel-search">
		<div class="search-item">
    		<yj-ht-city
				:type="1"
				ref="htCity"
				:isModalCity="isModalCity"
				@checkHtCity="checkHtCity"
				:disabled="disabled"
                @changeRouteRole2="changeRouteRole2"
			>
            </yj-ht-city>
		</div>
		<div class="search-item">
			<el-date-picker
				size="large"
				v-model="timer"
				:disabled="disabledDate"
                :clearable="true"
				type="daterange"
				range-separator="—"
				start-placeholder="开始日期"
				end-placeholder="结束日期"
				value-format="yyyy-MM-dd"
				:picker-options="pickerOptions(bookDateRange.minDay,bookDateRange.maxDay)"
			>
			</el-date-picker>
		</div>
		<div class="search-item">
			<el-select v-model="jdType" placeholder="请选择" >
				<el-option 
					v-for="item in options"
					:key="item.value"
					:label="item.label"
					:value="item.value"
				>
				</el-option>
			</el-select>
		</div>
		<div class="search-item">
			<yj-ht-city
				:type="2"
				:cityId="cityId"
				@checkHtCityGeo="checkHtCityGeo"
			></yj-ht-city>
		</div>
		<el-button
			type="primary"
			@click="searchHotel"
			:loading="loading"
			class="search-btn"
			>搜索</el-button
		>
		<yj-city-station  
			:ccsqdInfo="htCnCcsqD" 
			:paramsInfo="cityParamsInfo" 
			:visible="dialogVisible" 
			:changeRouteRole="changeRouteRole"
			@cancel="cancel"
			@determine="determine"></yj-city-station>
        <!-- <city-dialog :dialogVisible.sync="dialogVisible"/> -->
	</div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
// import cityDialog from '../HtCnComponent/cityDialog.vue'
export default {
	name: 'HotelList',
	props: {
		loading: {
			//父组件传入的lading
			type: Boolean,
			default: () => false,
		},
		bookDateRange:{
			type: Object,
			default(){
				return {};
			},
		},
		newCcsqdSearch:{
			type: Boolean,
			default: false,
		}
	},
	
    // components:{
    //     cityDialog
    // },
	data() {
		return {
            // changeRouteRole==2 
            cityShow:true,
            showCityVisible:false,
            dialogVisible:false,
			departCity: '',
			// ccsqdCityName:'',
			// pickerOptions: {
			// 	// 日期范围控制
			// 	// 1.当为因公模式下且为出差申请单模式下且changeDateRole==2  范围控制为出差申请单的范围
			// 	// 2.当因公模式下且为出差申请单模式下changeRouteRole==1 同时 changeDateRange >=0 就为日期有限制
			// 	// 3.否则默认情况下任选今天的之后的.
			// 	disabledDate: (time) => {
			// 		//使用箭头函数，防止this丢失
			// 		if (this.tripType == 1 && this.changeDateRole == 2 && this.bookStyle == 2)			
			// 		 {
			// 			return (
			// 				time.getTime() >
			// 					new Date(
			// 						this.htCnCcsqD.detail.dateEnd
			// 					).getTime() ||
			// 				time.getTime() <
			// 					new Date(
			// 						this.htCnCcsqD.detail.dateBegin
			// 					).getTime()
			// 			)
			// 		} else if (
			// 			this.tripType == 1 &&
			// 			this.changeRouteRole == 1 &&
			// 			this.changeDateRange > 0 &&
			// 			this.bookStyle == 2
			// 		) {
			// 			// 行程前后this.changeDateRange 天,同时超过今天之前的无法选择
			// 			const minTime = new Date(
			// 				this.$moment(this.departDate)
			// 					.subtract(this.changeDateRange, 'days')
			// 					.format('YYYY-MM-DD')
			// 			).getTime()
			// 			const maxTime = new Date(
			// 				this.$moment(this.arriveDate)
			// 					.add(this.changeDateRange, 'days')
			// 					.format('YYYY-MM-DD')
			// 			).getTime()
			// 			return (
			// 				time.getTime() < minTime ||
			// 				time.getTime() > maxTime ||
			// 				time.getTime() < Date.now() - 8.64e7
			// 			)
			// 		} else {
			// 			return time.getTime() < Date.now() - 8.64e7
			// 		}
			// 	},
			// },
			options: [
				{
					value: '',
					label: '请选择',
				},
				{
					value: 1,
					label: '全部',
				},
				{
					value: 2,
					label: '协议',
				},
			],
			cityParamsInfo: { //当前城市行息
				cityType: 1,
				cityId:'',
				szm:'',
				proType:10903,
			},
			
		}
	},
	
	computed: {
		...mapState({
			htCnQuery: (state) => state.htCn.htCnQuery, //
			htCnCcsqD: (state) => state.htCn.htCnCcsqD, //
			htCnParams: (state) => state.htCn.htCnParams,
		}),
		changeDateRole() {
			return this.htCnQuery.changeDateRole
		},
		changeRouteRole() {
			// 此处改为调用方法
			return this.$common.getVipParamterByParNo(this.$store,10991,0,'value1',10903) ;
		},
		changeDateRange() {
			return this.htCnQuery.changeDateRange
		},
		tripType() {
			/* 默认当前因公因私*/
			return this.htCnQuery.tripType
		},
		bookStyle() {
			// 1单订模式  2出差申请单模式
			let bookStyle = this.$common.getBookStyle(this.$store,10903);
			return bookStyle
		},
		disabled() {
            if(this.newCcsqdSearch){
                return true
            }
			// 出差申请单模式下，因公模式，changeRouteRole==0的时候禁用

			if (this.tripType == 1 && this.changeRouteRole == 0 && this.bookStyle == 2) {
				return true
            }else{
                return false
            }
		},
		disabledDate() {
			// 出差申请单模式下，因公模式changeDateRole==0的时候禁用，
            if(this.newCcsqdSearch){
                return true
            }
			if (
				this.tripType == 1 &&
				this.changeDateRole == 0 &&
				this.bookStyle == 2
			) {
				return true
			} else {
				return false
			}
		},
		departDate() {
			return this.htCnCcsqD?.currentRoute?.departDate //入住日期
		},
		arriveDate() {
			return this.htCnCcsqD?.currentRoute?.arriveDate // 离店日期
		},
		// 控制站点选择
		isModalCity(){  
			// 当城市被禁用的时候应该无法触发弹窗！
            // 什么情况下城市选择器无法出来？
            // 因公模式且为出差申请单模式下且changeRouteRole!=1的情况 ，changeRouteRole为2或3的时候才会触发有弹窗的出现！
			let bool = false;
			if(this.tripType==1 && this.bookStyle==2 && this.changeRouteRole==0){
			    bool = false;
			}else if(this.tripType==1 && this.bookStyle==2 && this.changeRouteRole==1){
			    bool = true;
			}else if(this.tripType==1 && this.bookStyle==2 && (this.changeRouteRole==2 || this.changeRouteRole==3)){
			    bool = false;
			    this.$emit("changeRouteRole2")
			}else{
			    bool = true;
			}
			return bool
		},
		cityId: {
			get() {
				return this.htCnParams.cityId
			},
			set(val) {
				this.SET_HTCN_PARAMS({ cityId: val })
			},
		},
		timer: {
			get() {
				return [
					this.htCnParams.checkInDate,
					this.htCnParams.checkOutDate,
				]
			},
			set(val) {
                if(!val){
                    this.SET_HTCN_PARAMS({ checkInDate: ''})
                    this.SET_HTCN_PARAMS({ checkOutDate: '' })
                }else{
                    this.SET_HTCN_PARAMS({ checkInDate: val[0] })
                    this.SET_HTCN_PARAMS({ checkOutDate: val[1] })
                }  
			},
		},
		jdType: {
			get() {
				return this.htCnParams.jdType
			},
			set(val) {
				this.SET_HTCN_PARAMS({ jdType: val })
			},
		},
		searchType: {
			get() {
				return this.htCnParams.searchType
			},
			set(val) {
				this.SET_HTCN_PARAMS({ searchType: val })
			},
		},
		keywords: {
			get() {
				return this.htCnParams.keywords
			},
			set(val) {
				this.SET_HTCN_PARAMS({ keywords: val })
			},
		},
		lat: {
			get() {
				return this.htCnParams.lat
			},
			set(val) {
				this.SET_HTCN_PARAMS({ lat: val })
			},
		},
		lon: {
			get() {
				return this.htCnParams.lon
			},
			set(val) {
				this.SET_HTCN_PARAMS({ lon: val })
			},
		},
		businessZone: {
			get() {
				return this.htCnParams.businessZone
			},
			set(val) {
				this.SET_HTCN_PARAMS({ businessZone: val })
			},
		},
		businessZoneName: {
			get() {
				return this.htCnParams.businessZoneName
			},
			set(val) {
				this.SET_HTCN_PARAMS({ businessZoneName: val })
			},
		},
		district: {
			get() {
				return this.htCnParams.district
			},
			set(val) {
				this.SET_HTCN_PARAMS({ district: val })
			},
		},
		districtName: {
			get() {
				return this.htCnParams.districtName
			},
			set(val) {
				this.SET_HTCN_PARAMS({ districtName: val })
			},
		},
		ccsqdCityName: {
			get() {
				return this.htCnParams.ccsqdCityName
			},
			set(val) {
				this.SET_HTCN_PARAMS({ ccsqdCityName: val })
			},
		},
	},
	watch: {
		htCnCcsqD: {
			handler(newVAL) {
				// 出差申请单模式下，因公模式 选择出差申请单时候，城市会随着出差申请单行程变化(只有changeRouteRole==0和1的时候才生效，为2和三的时候不生效)
				if (this.tripType == 1 && this.bookStyle == 2) {
                    //console.log("出差申请单发生了变化")
					// this.cityId = newVAL?.currentRoute?.departCity
					this.ccsqdCityName = newVAL?.currentRoute?.departCityName
					// this.timer = [this.departDate, this.arriveDate]
				} else {
					return
				}
			},
			deep: true,
			immediate: false,
		},
	},
	mounted() {
		// console.log(this.htCnCcsqD.currentRoute.departCity,"初始化城市id")
		// if(this.tripType==1 && this.changeRouteRole==0 && this.bookStyle==2 ){
		// }
		
		this.getkgfs()
	},
	methods: {
		...mapMutations({
			SET_HTCN_PARAMS: 'htCn/SET_HTCN_PARAMS', // 设置查询参数
			SET_HTCN_QUERY: 'htCn/SET_HTCN_QUERY', // 保存因共模式下的自定义信息
		}),
		...mapGetters({
			getVipParamterByParNo: 'common/getVipParamterByParNo',
		}),
		getkgfs() {
			//这是出差申请单模式的管控规则
			let params10999 = this.$common.getVipParamterByParNo(this.$store,10999, 0)
			let params10991 = this.$common.getVipParamterByParNo(this.$store,10991, 0,"value1",10903)
			let params10992 = this.$common.getVipParamterByParNo(this.$store,10992, 0)
			let params10993 = this.$common.getVipParamterByParNo(this.$store,10993, 0)
			let params10995 = this.$common.getVipParamterByParNo(this.$store,10995, 0)
			let params10996 = this.$common.getVipParamterByParNo(this.$store,10996, 0)
			let params20002 = this.$common.getVipParamterByParNo(this.$store,20002, 0)
			let params20003 = this.$common.getVipParamterByParNo(this.$store,20003, 0)
				
			this.SET_HTCN_QUERY({
				gkfsRole: params10999, //0 不管控  1出差申请单管控  2差旅标准管控
				changeRouteRole: params10991, //根据出差申请单预订时可更改行程
				changeDateRole: params10992, //"查询出差申请单预订行程日期可否变更
				changeDateRange: params10993, //根据出差申请单预订时可更改出行日期
				params10995, //  "成本中心是否必填"
				params10996, //   "项目是否必填"
				params20002, //   "订单填写页面是否显示成本中心，1显示，0不显示"
				params20003, //   "订单填写页面是否显示项目，1显示，0不显示"
			})
		},
		pickerOptions(minDay,maxDay){
			return {
				//定义不可选择时间 暂无预约功能 查找有效日期
				// 查询出差申请单预订行程日期可否变更 0不可更改  1 按行程更改 2 出差申请单日期范围更改
				disabledDate(time) {
					let minTime = new Date(new Date(minDay).toLocaleDateString()).getTime();
					let maxTime = new Date(new Date(maxDay).toLocaleDateString()).getTime();
					return time.getTime() < minTime  || time.getTime() > maxTime;
				},
			}
		},
		checkHtCity(val) {
			this.cityId = val.id
            this.ccsqdCityName=val.cityName
			this.$emit('getNewCityId', val) //传递最新的CiTY id更新列表
		},
		checkHtCityGeo(val) {
			this.searchType = val.type || val.poiType || ''
			this.keywords = val.name
			this.lat = val.lat
			this.lon = val.lng
			if (val.type == 1 || val.poiType == 1) {
				this.district = val.id || ''
				this.districtName = val.name || ''
				this.businessZoneName = ''
				this.businessZone = ''
			}
			if (val.type == 2) {
				this.district = ''
				this.districtName = ''
				this.businessZone = val.id || ''
				this.businessZoneName = val.name || '';
			}
			if (val.poiType == 3) {
				this.district = '' 
				this.districtName = ''
				this.businessZoneName = '' 
				this.businessZone = ''
			}
		},
        changeRouteRole2(){
            // 触发弹窗之前先判断是否有出差申请单ID 如果没有弹出警告！
            if(this.tripType == 1 && JSON.stringify(this.htCnCcsqD)!='{}'){
                // console.log("走到了这里")
				this.cityParamsInfo = {
					cityType: 1,
					cityId:this.htCnParams.cityId,
					szm:'',
					proType:10903,
				}
                this.dialogVisible=true;
				
                // this.showCityVisible=true
            }else{
                this.$message({
						type: 'error',
						message: '因公模式下请先选择你的出差申请单',
				})
            }

            
        },
		goDetail(num) {
			this.$emit('toHtDetail', num)
		},

		searchHotel() {
			
			// 地点日期必填！
			// 因共出差申请单的情况下，出差申请单必填，先判断因共因私			
			// 新增出差申请单时触发
			if(this.newCcsqdSearch){
				this.$emit('searchHotel')
				return 
			}
		
			if (!this.cityId || !this.htCnParams.ccsqdCityName ) {
                // console.log("this.cityId && !this.htCnParams.ccsqdCityName")
				this.$message({
					type: 'error',
					message: '请先输入城市！',
				})
			} else if (this.timer.length < 1 ) {
                 // console.log("this.timer.length < 1")
				this.$message({
					type: 'error',
					message: '请输入你的时间',
				})
                
			} else if(this.$dateTime.getDiffDay3(this.htCnParams.checkInDate,this.$dateTime.getToDay(),)<0){
                this.$message({
					type: 'error',
					message: '查询时间不允许小于当前时间',
				})
            }
            
            
            else {
				if (this.tripType == 1 && this.bookStyle == 2) {
					if (this.htCnCcsqD.ccsqdId) {
						this.$emit('searchHotel')
					} else {
						this.$message({
							type: 'error',
							message: '因公模式下请先选择你的出差申请单',
						})
					}
				}
                 else {
                    // console.log("sels")
					this.$emit('searchHotel')
				}
			}
		},
		cancel(bool){
			this.dialogVisible = bool;
		},
		determine(info){
			var obj = {};
			obj = {
					ccsqdCityName: info.cityName, //  出发城市
					cityId: info.cityId,// 出发城市ID
					checkInDate: '', //日期开始时间
					checkOutDate: '', // 日期结束时间
				}
			this.SET_HTCN_PARAMS(obj);
			this.dialogVisible = false;
		},
	},
}
</script>

<style scoped lang="scss">
$bcolor: #006bb9;
/deep/ .el-input__prefix {
	color: #006bb9;
	text-align: center;
	width: 25px;
}
.hotel-search {
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	padding: 18px 0;
	.search-item {
		margin-right: 20px;
		.ht-city {
			position: relative;
			.ht-city-modal {
				position: absolute;
				top: 40px;
				left: 0;
			}
		}
		/deep/ .el-input__inner {
			height: 45px;
			border: 2px solid #d9e1ed;
			border-radius: 0px;
		}
		.city-ht {
			height: 500px;
			overflow: auto;
		}
	}
	.search-btn {
		width: 100px;
		height: 45px;
		color: #fff;
		cursor: pointer;
		border-radius: 0;
		font-size: 14px;
	}
	/deep/ .el-date-editor .el-range-separator {
		padding: 0 5px;
		line-height: 32px;
		width: 10%;
		color: #303133;
	}
}
</style>
